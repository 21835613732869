<template>
    <el-dialog :model-value="show" title="数据导出" width="450px" @close="close" @open="open">
        <div class="download_container">
            <p>数据总数：{{ countNum }}</p>
            <div style="display: flex; justify-content: center">
                <el-button type="primary" plain @click="createDownload('excel')">导出为Excel，前二十万条</el-button>
                <el-button type="primary" plain @click="createDownload('csv')">全量数据，导出为csv</el-button>
            </div>
        </div>
    </el-dialog>
</template>

<script setup>
import { apiEgpmDownloadCreate, apiEgpmBrandTaskResultCount } from "@/request/egpmBrandApi";
import withLoading from "@/utils/loading";
import { useRoute, useRouter } from "vue-router";
import { ElMessage } from "element-plus";
import { ref } from "vue";

const props = defineProps({
    show: Boolean,
    resultStore: { type: Object, default: {} },
});
const emit = defineEmits(["close"]);

const router = useRouter();
const route = useRoute();
const countNum = ref(0);

// 创建下载任务-全量
async function createDownload(fileType) {
    const { code } = await withLoading(apiEgpmDownloadCreate)({ taskId: route.query.id, flag: 1, fileType, ...props.resultStore.sreen });
    if (code !== "0") return;
    close();
    const href = router.resolve({
        path: `/egpm/brand/download`,
        query: { reportId: route.query.reportId },
    }).href;
    window.open(href, "_blank");
}

// 查需数据总量
async function count() {
    const params = {
        taskId: route.query.id,
        count: 1,
        ...props.resultStore.sreen,
    };
    const { data } = await apiEgpmBrandTaskResultCount(params);
    countNum.value = data || 0;
}

// 打开
function open() {
    count();
}

function close() {
    emit("close");
    countNum.value = 0;
}
</script>

<style lang="scss" scoped>
.download_container {
    p {
        padding-bottom: 30px;
        text-align: center;
        font-size: 16px;
        color: #333;
    }
}
</style>
