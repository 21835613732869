import { ref, watch, nextTick } from "vue";
import { apiEgpmBrandTaskResultStatsCity } from "@/request/egpmBrandApi";
import echarts from "@/utils/echarts";

export default ({ props, route, batch, province }) => {

    let myChart = null;
    const cityLoading = ref(false);

    async function getData() {
        if (cityLoading.value) return;
        if (!province.value) {
            initBarChart([]);
            return;
        }
        cityLoading.value = true;
        try {
            const params = {
                taskId: route.query.id,
                batch: batch.value || '',
                brandName: props.dish.name,
                province: province.value || '',
            }
            const { data } = await apiEgpmBrandTaskResultStatsCity(params);
            nextTick(() => {
                initBarChart(data || []);
            })
        } finally {
            cityLoading.value = false;
        }
    }

    // resize
    function handleResize() {
        myChart && myChart.resize();
    }

    function initBarChart(data) {
        handleDispose();
        const chartDom = document.getElementById("city_chart_box");
        myChart = echarts.init(chartDom);
        const yAxisData = data.map(item => item?.name).reverse();
        const seriesData = data.map(item => item?.value).reverse();
        const len = seriesData.length;
        let titleText = `品牌门店城市分布`;
        if (batch.value) {
            titleText += `(${batch.value})`;
        }
        if (province.value) {
            titleText += `(${province.value})`;
        }
        const option = {
            title: {
                text: titleText,
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            grid: {
                top: 30,
                left: 5,
                bottom: 30,
                containLabel: true,
            },
            dataZoom: [
                {
                    show: true,
                    type: "slider",// 设置类型
                    showDetail: false,// 是否显示detail，即拖拽时候显示详细数值信息。
                    yAxisIndex: [0],
                    filterMode: "none",
                    width: 18,// 滚动条宽度
                    zoomLoxk: true,// 是否锁定选择区域（或叫做数据窗口）的大小
                    // 组件离容器上侧的距离
                    // 如果top的值为'top', 'middle', 'bottom'，组件会根据相应的位置自动对齐
                    top: "middle",
                    startValue: len - 18,// 滚动条开始的数据位置
                    endValue: len,
                    minValueSpan: 18,// 最小显示条数
                    maxValueSpan: 18,// 最大显示条数
                    brushSelect: false
                },
                {
                    // 没有下面这块的话，只能拖动滚动条，
                    // 鼠标滚轮在区域内不能控制外部滚动条
                    type: "inside",
                    // 控制哪个轴，如果是number表示控制一个轴，
                    // 如果是Array表示控制多个轴。此处控制第二根轴
                    yAxisIndex: [0],
                    // 滚轮是否触发缩放
                    zoomOnMouseWheel: false,
                    // 鼠标移动能否触发平移
                    moveOnMouseMove: true,
                    // 鼠标滚轮能否触发平移
                    moveOnMouseWheel: true,
                },
            ],
            xAxis: {
                type: "value",
                axisLabel: {
                    rotate: 30,
                }
            },
            yAxis: {
                type: "category",
                data: yAxisData,
                axisLabel: {
                    interval: 0,
                    formatter: (val) => {
                        if (val?.length >= 10) {
                            return `${val.substring(0, val.length / 2)}\n${val.substring(val.length / 2)}`;
                        }
                        return val;
                    }
                }
            },
            series: [
                {
                    type: "bar",
                    barWidth: 20,
                    label: {
                        show: true,
                        position: 'right'
                    },
                    data: seriesData
                },
            ],
        };
        // resize
        window.addEventListener("resize", handleResize);
        option && myChart.setOption(option);
    }

    // 处理销毁
    function handleDispose() {
        window.removeEventListener("resize", handleResize);
        myChart && myChart.dispose();
        myChart = null;
        cityLoading.value = false;
    }

    watch(() => province.value, (newVal, oldVal) => {
        if (newVal === oldVal) return;
        handleDispose();
        props.show && getData();
    })

    return {
        cityLoading,
        getData,
        handleDispose
    }
}